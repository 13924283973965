import React, {memo, useState} from "react"
import style from "styles/playtoearn.module.css"
import useLocale from '../../../../hooks/useLocale'

const LearnTheStory = () => {
	const {learnTheStorySection} = useLocale()
	const videos: { text: string, video: string }[] = [
		{
			text: learnTheStorySection.firstStory,
			video: 'https://www.youtube.com/embed/pG4N1D41CIk'
		},
		{
			text: learnTheStorySection.secondStory,
			video: 'https://www.youtube.com/embed/TsDOadqIKbw'
		},
		{
			text: learnTheStorySection.thirdStory,
			video: 'https://www.youtube.com/embed/RYCNlyqv6F0'
		},
		{
			text: learnTheStorySection.fourthStory,
			video: 'https://www.youtube.com/embed/ibkXeElgSrQ'
		},
	]
	const [currentTab, setCurrentTab] = useState(0)

	const handleStoryChange = (storyIndex: number) => {
		setCurrentTab(storyIndex)
	}

	return (
		<div className={style.playLearn}>
			<div className={style.container}>
				<div className={style.intro}>
					<div className={style.introContainer}>
						<h2 className={style.title} data-aos-once="true" data-aos="slide-up">{learnTheStorySection.title}</h2>
						<div className={`md:flex hidden justify-center space-x-5 text-white`}>
							{videos.map((item, index) => (
								<div key={index} className="flex flex-col">
									<div
										className={`cursor-pointer font-Industry font-semibold md:text-base text-xs uppercase letter_space`}
										onClick={() => {
											index !== currentTab ? handleStoryChange(index) : null
										}}
									>{item.text}</div>
									<div className="mt-1 relative">
										<div
											style={{animationDuration: 5000 + "ms"}}
											className={`${index === currentTab ? "bg-[#D7FC51] h-[3px]" : ""}  w-full absolute z-10 my-auto top-0 bottom-0 -mt-2`}>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className={`w-full md:hidden text-white grid grid-cols-2 gap-2 mt-2`}>
							{videos.map((item, index) => (
								<div key={index} className="flex flex-col">
									<div
										className={`cursor-pointer font-Industry font-semibold text-[13px] uppercase letter_space`}
										onClick={() => {
											index !== currentTab ? handleStoryChange(index) : null
										}}
									>{item.text}</div>
									<div className="mt-1 relative w-full">
										<div
											style={{animationDuration: 5000 + "ms"}}
											className={`${index === currentTab ? "bg-[#D7FC51] h-[3px]" : ""}  w-full absolute z-10 my-auto top-0 bottom-0 -mt-2`}>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="flex justify-center items-center md:mt-7 mt-4">
					{videos.map((item, index) => {
						if (index === currentTab) {
							return (
								<div className="w-full" key={item.text}>
									<iframe
										loading="lazy"
										className="w-full lg:h-[635px] h-[300px]"
										src={item.video}
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									/>
								</div>
							)
						} else {
							return null
						}
					})}
				</div>
			</div>
		</div>
	)
}

export default memo(LearnTheStory)
